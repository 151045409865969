import UserService from '@/services/user.service'
import { useMutation } from '@tanstack/vue-query'
import { NotificationStore } from '@/store/notification.store'

export const useReferUser = () => {
    const { $userService, $notificationStore } = useNuxtApp()
    const userService = $userService as UserService
    const notificationStore = $notificationStore as NotificationStore
    return useMutation({
        mutationFn: userService.referUser,
        mutationKey: ['refer'],
        onSuccess: () => {
            notificationStore.notifySuccess({
                summary: 'User referred successfully',
                detail: '',
                life: 3000,
            })
        },
        onError: () => {
            notificationStore.notifyError({
                summary: 'Error refering user',
                detail: '',
                life: 3000,
            })
        },
    })
}
