<script lang="ts" setup>
import { object, string, array, set } from 'zod'
import { useForm, ErrorMessage, useFieldArray } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import { useReferUser } from '@/composables/useReferUser'

defineProps<{
    showModal: boolean
}>()

const emit = defineEmits<{
    'update:display': [display: boolean]
}>()

const { mutateAsync, isPending } = useReferUser()

const referUserSchema = toTypedSchema(
    object({
        referEmails: array(string().email({ message: 'Invalid email address' }))
            .min(1, {
                message: 'Please add at least one email address.',
            })
            .refine(
                (val) => {
                    const uniqueEmails = new Set(val)
                    return uniqueEmails.size === val.length
                },
                {
                    message: 'Duplicate email addresses are not allowed.',
                }
            ),
    })
)
const { handleSubmit, resetForm, errors } = useForm({
    validationSchema: referUserSchema,
    initialValues: { referEmails: [] },
    initialTouched: { referEmails: false },
})

const { fields, replace } = useFieldArray<string>('referEmails')

const updateDisplay = (value: boolean) => {
    emit('update:display', value)
}

const closeModal = () => {
    updateDisplay(false)
}

const onSubmit = handleSubmit(async (values) => {
    await mutateAsync(values)
    resetForm()
    closeModal()
})
</script>

<template>
    <Dialog
        :visible="showModal"
        :closeOnEscape="true"
        modal
        header="Share the Love"
        position="top"
        :draggable="false"
        @update:visible="closeModal"
        :closable="true"
        :style="{ width: '32rem' }"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    >
        <form @submit.prevent="onSubmit">
            <div class="flex flex-column gap-2 mt-1">
                <p>
                    Give $20 & Get $20: Give someone else $20 credit to trial AI
                    Legal Assistant and we will give you $20 credit.
                </p>
                <p>
                    If they decide to continue after the trial we will add an
                    additional $100 to both of your accounts.
                </p>
                <p class="mb-0">
                    Input an email then press ENTER. Input up to 5 at once.
                </p>
                <small>
                    <span class="font-bold"> Note: </span> You can't refer team
                    members or yourself.
                </small>
                <Chips
                    type="email"
                    :class="{
                        'p-invalid': errors.referEmails,
                    }"
                    class="w-full"
                    :pt="{
                        container: {
                            class: 'w-full flex gap-2',
                        },
                    }"
                    placeholder="Email"
                    :max="5"
                    :modelValue="fields.map((field) => field.value)"
                    @update:model-value="(val: string[]) => replace(val)"
                    aria-describedby="email-error"
                />

                <small class="p-error mt-1 text-left block w-full">
                    <ErrorMessage name="referEmails" />
                </small>

                <Button
                    :loading="isPending"
                    type="submit"
                    class="w-fit ml-auto mt-2"
                    size="small"
                >
                    <template #default>
                        <i
                            v-if="isPending"
                            class="pi pi-spinner pi-spin mr-2"
                        ></i>
                        Refer
                    </template>
                </Button>
            </div>
        </form>
    </Dialog>
</template>
<style scoped lang="scss"></style>
