<script setup lang="ts">
defineProps<{
    showModal: boolean
}>()

const emit = defineEmits<{
    'update:display': [display: boolean]
}>()

const closeModal = () => {
    emit('update:display', false)
}
</script>

<template>
    <Dialog
        :visible="showModal"
        @update:visible="closeModal"
        :modal="true"
        style="width: 90vw; height: 90vh"
        header="Join The Weekly Training"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
        :pt="{
            header: 'pb-2 align-items-start',
            content: 'h-full pb-0',
        }"
    >
        <template #header>
            <div class="flex flex-column">
                <h5>Join The Weekly Training</h5>
                <p class="p-text-secondary">
                    Have all your questions answered and see new use cases from
                    other members.
                </p>
            </div>
        </template>
        <div class="h-full w-full">
            <div class="h-full w-full">
                <iframe
                    src="https://tidycal.com/samueljunghenn/ai-legal-assistant-training"
                    frameborder="0"
                    allowfullscreen
                    class="tidycal-embed h-full w-full"
                >
                </iframe>
            </div>
          
        </div>
    </Dialog>
</template>

<style scoped lang="scss">

</style>
