<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import MenuOptions from '@/components/nav/MenuOptions.vue'
import EditorOptions from '@/components/nav/EditorOptions.vue'
import { AuthStore } from '@/store/auth.store'
import { useQueryClient } from '@tanstack/vue-query'
import * as Sentry from '@sentry/vue'

const { $authStore } = useNuxtApp()
const authStore = $authStore as AuthStore
const route = useRoute()
const { layoutEditor } = useLayout()
const isEditorPage = computed(() => {
    return route.name === 'd-id' ? true : false
})

const [referDialogVisible, toggleReferDailog] = useToggle(false)
const queryClient = useQueryClient()
const logout = () => {
    authStore.setTokens(null, null)
    authStore.isLogged = false
    queryClient.removeQueries()
    Sentry.setUser(null)
    navigateTo('/auth/login')
}

const userMenu = ref()
const userOptions = ref([
    {
        label: 'Subscription',
        icon: 'pi pi-wallet',
        command: () => {
            navigateTo('/pricing')
        },
    },
    {
        label: 'Billing',
        icon: 'pi pi-wallet',
        command: () => {
            navigateTo('/billing')
        },
    },
    {
        label: 'Team',
        icon: 'pi pi-users',
        command: () => {
            navigateTo('/members')
        },
    },
    {
        label: 'Share the Love',
        icon: 'pi pi-heart',
        command: () => {
            toggleReferDailog()
        },
    },
    // {
    //     label: 'Integrations',
    //     icon: 'pi pi-cloud',
    //     command: () => {
    //         navigateTo('/integrations')
    //     },
    // },
    {
        label: 'Settings',
        icon: 'pi pi-cog',
        command: () => {
            navigateTo('/settings/profile')
        },
    },
    {
        separator: true,
    },
    {
        label: 'Log Out',
        icon: 'pi pi-sign-out',
        command: () => {
            logout()
        },
    },
])

const toggleUserOptions = (evt: any) => {
    userMenu.value.toggle(evt)
}
</script>
<template>
    <div
        class="ly-topbar"
        :class="{
            'pointer-events-none':
                isEditorPage && layoutEditor.editorInstance.value === null,
        }"
    >
        <div
            class="flex-grow-1 h-full overflow-hidden"
            style="max-height: inherit"
        >
            <MenuOptions v-if="!isEditorPage"></MenuOptions>
            <EditorOptions v-else></EditorOptions>
        </div>
        <div
            class="flex justify-content-center align-content-center flex-wrap"
            style="height: 48px"
        >
            <Avatar
                class="mr-2 cursor-pointer"
                shape="circle"
                style="width: 40px; height: 40px"
                :style="{
                    'background-color': authStore?.userData?.picture
                        ? '#fff'
                        : 'navajowhite',
                }"
                :image="
                    authStore?.userData?.picture
                        ? authStore.userData.picture
                        : null
                "
                :icon="authStore?.userData?.picture ? null : 'pi pi-user'"
                @click="toggleUserOptions"
                aria-haspopup="true"
                aria-controls="userMenu"
            />
            <TieredMenu
                ref="userMenu"
                id="userMenu"
                :model="userOptions"
                popup
            />
        </div>
        <ReferUser
            :showModal="referDialogVisible"
            @update:display="toggleReferDailog"
        />
    </div>
</template>
<style lang="scss">
.ly-topbar {
    margin: 2px 8px 10px 8px; //8px 8px 12px 8px
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-height: 53px;
    height: 100%;
    padding-top: 7px;

    ul {
        margin: 0;
        padding: 0;
    }

    .p-button:focus,
    .p-inputtext:focus {
        box-shadow: none !important;
    }
}
</style>
