<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted, watch, nextTick } from 'vue'
import { useConfirm } from 'primevue/useconfirm'
import DocumentService, { SelectedDocument } from '@/services/document.service'
import { NotificationStore } from '@/store/notification.store'
import generateRandomString from '@/utils/helpers/generateRandomString'
import AudioService from '@/services/audio.service'
import { AuthStore } from '@/store/auth.store'
import SearchDocumentsControl from '@/components/editor/SearchDocumentsControl.vue'
import InputText from 'primevue/inputtext'
import {
    useCreateDocument,
    useDocument,
    useSaveDocumentTitle,
} from '@/composables/document'
import AuthService from '@/services/auth.service'
import { useIsMutating, useMutation } from '@tanstack/vue-query'
import { MenuItem } from 'primevue/menuitem'
import { useToggle } from '@vueuse/core'
import { AxiosProgressEvent } from 'axios'
import ProgressBar from 'primevue/progressbar'
import { useToast } from 'primevue/usetoast'
import ShareDocumentDialog from '@/components/DocumentCard/ShareDocumentDialog.vue'
import { defaultDocumentContent } from '@/utils/helpers/editor'
import MultiDiscussDailog from '../MultiDoc/MultiDiscussDailog.vue'
import { AppAbility } from '@/utils/ability'
import { useAbility } from '@casl/vue'
import { useDocumentText } from '@/composables/document/useDocumentText'

const {
    $documentService,
    $notificationStore,
    $audioService,
    $authStore,
    $authService,
} = useNuxtApp()
const documentService = $documentService as DocumentService
const notificationStore = $notificationStore as NotificationStore
const audioService = $audioService as AudioService
const authStore = $authStore as AuthStore
const authService = $authService as AuthService
const confirm = useConfirm()

/* Other options */
const {
    toggleEditorSidebar,
    savingDocLoader,
    updateLayoutEditor,
    layoutEditor,
} = useLayout()
/* *** */

const route = useRouter()

const fileUploadProgress = ref(0)
const isTranscribing = ref(false)
const toast = useToast()

let isLoading = ref(false)
const { visible: helpDialogVisible, toggle: toggleHelpDialog } = useHelpDailog()
const [shareDialogVisible, toggleShareDialog] = useToggle(false)
const [mutliDiscussDialogVisible, toggleMutliDiscussDialog] = useToggle(false)
const { can } = useAbility<AppAbility>()
const { mutateAsync, isPending: isSavingDocumentTitle } = useSaveDocumentTitle()

const documentId = ref(route?.currentRoute?.value?.params?.id ?? '')
const { data: documentData, isPending: documentIsPending } =
    useDocument(documentId)

const isMutating = useIsMutating({
    predicate: (mutation) => {
        return (
            (mutation.options.mutationKey?.includes('saveDocumentTitle') ||
                mutation.options.mutationKey?.includes(
                    'saveDocumentContent'
                )) ??
            false
        )
    },
})

watch([isTranscribing, fileUploadProgress], ([transcribing, progress]) => {
    if (!transcribing && progress === 100) {
        toast.removeGroup('upload')
    }
})
const handleAudioUpload = async (files: File[]) => {
    isLoading.value = true
    try {
        if (!files || files.length === 0) {
            throw new Error('Problem with loading the audio file.')
        }
        let formData = new FormData()
        formData.append('file', files[0])

        toast.add({
            severity: 'info',
            summary: 'Transcribing audio...',
            group: 'upload',
            closable: false,
        })
        isTranscribing.value = true
        const textResponse = await audioService.aiTranscribe(formData, {
            onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                if (progressEvent.total) {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent?.total
                    )

                    fileUploadProgress.value = percentCompleted
                }
            },
        })

        isTranscribing.value = false

        if (textResponse?.transcript?.length > 0) {
            layoutEditor.editorInstance.value?.ej2Instances?.documentEditor?.editor?.insertText(
                textResponse?.transcript.trim()
            )

            layoutEditor?.editorInstance?.value?.ej2Instances?.documentEditor?.focusIn()
        } else {
            notificationStore.notifyError({
                summary: 'Cannot transcribe provided audio.',
            })
        }
    } catch (err) {
        notificationStore.notifyError({
            summary: 'Problem with uploading audio.',
            detail: err.message,
        })
        console.log(err)
    } finally {
        isLoading.value = false
        toast.removeGroup('upload')
        isTranscribing.value = false
        fileUploadProgress.value = 0
    }
}

const { mutate, isPending } = useCreateDocument()

const createDocument = () => {
    mutate({
        editor_id: generateRandomString(44),
        title: 'Untitled document',
        content: defaultDocumentContent,
        collaborators: [],
    })
}
const [contentUploader, toggleContentUploader] = useToggle(false)

/* Menu options */
const fileMenu = ref()
const fileMenuOptions = ref([
    {
        label: 'Create',
        icon: 'pi pi-file',
        command: createDocument,
    },
    {
        label: 'Clone',
        icon: 'pi pi-clone',
        command: async () => {
            const isCloned = await documentService.cloneDocument(
                documentData.value?.Id ?? ''
            )
            if (!isCloned) {
                notificationStore.notifyError({
                    summary: 'Error cloning the document',
                })
            } else {
                notificationStore.notifySuccess({
                    summary: 'The document has been cloned successfully',
                })
                navigateTo(`/d/${isCloned}`, {
                    open: { target: '_blank' },
                })
            }
        },
    },
    {
        separator: true,
    },
    // {
    //     label: 'Import',
    //     icon: 'pi pi-file-import',
    //     command: () => {
    //         const btn = document.querySelector(
    //             "div.e-toolbar-item[aria-label='Open a document.'] > button"
    //         )
    //         if (btn) {
    //             btn.click()
    //         }
    //     },
    // },
    {
        label: 'Export DOCX',
        icon: 'pi pi-download',
        command: async () => {
            layoutEditor.editorInstance.value?.ej2Instances?.documentEditor?.save(
                layoutEditor.editorInstance.value?.ej2Instances?.documentEditor
                    ?.documentName,
                'Docx'
            )
        },
    },
    {
        label: 'Print & PDF',
        icon: 'pi pi-print',
        command: async () => {
            if (layoutEditor.editorInstance.value) {
                layoutEditor.editorInstance.value?.ej2Instances?.documentEditor?.print()
            }
        },
    },
    {
        separator: true,
    },
    {
        label: 'Delete',
        icon: 'pi pi-trash',
        command: () => {
            confirm.require({
                group: 'deleteDialog',
                message:
                    documentData.value?.ownerId == authStore.userData.email
                        ? `Are you sure you want to delete this document? Type "DELETE" into the field to confirm.`
                        : 'To confirm you want to remove the document from your account type "REMOVE".',
                header:
                    documentData.value?.ownerId == authStore.userData.email
                        ? 'Delete Document'
                        : 'Remove Document',
                icon: 'pi pi-exclamation-triangle',
                position: 'top',
                acceptClass: 'p-button-primary',
                rejectClass: 'p-button-primary',
                accept: async () => {
                    if (
                        documentData.value?.ownerId !== authStore.userData.email
                    ) {
                        removeCollaboratorMutation.mutate(
                            documentData.value?.Id ?? ''
                        )
                        return
                    }
                    const isRemoved = await documentService.removeDocument(
                        documentData.value?.Id ?? ''
                    )
                    if (!isRemoved) {
                        notificationStore.notifyError({
                            summary: 'Error removing the document',
                        })
                    } else {
                        notificationStore.notifySuccess({
                            summary:
                                'The document has been removed successfully',
                        })
                        navigateTo('/')
                    }
                },
            })
        },
    },
])

const mainOptions = computed(() => {
    return [
        {
            label: 'File',
            visible: true,
            click: (evt: any) => {
                fileMenu.value.toggle(evt)
            },
        },
        {
            label: 'Import',
            visible: true,
            click: toggleContentUploader,
        },
        {
            label: 'Share',
            visible: authStore?.userData?.email === documentData.value?.ownerId,
            click: () => toggleShareDialog(true),
        },
        {
            label: 'Help',
            visible: true,
            click: () => {
                toggleHelpDialog()
            },
        },
        {
            label: '',
            icon: 'pi pi-search',
            visible: browserWidth.value < 1024,
            click: () => {
                searchDocMobileVisible.value = true
            },
        },
    ]
})
/* *** */
const removeCollaboratorMutation = useMutation({
    mutationFn: documentService.removeCollaborator,
    onSuccess: () => {
        notificationStore.notifySuccess({
            summary: 'The document access has been revoked successfully',
        })
        navigateTo('/')
    },
    onError: () => {
        notificationStore.notifyError({
            summary: 'Error revoking access',
        })
    },
})

/* Document title */
const docTitleHiddenEl = ref<HTMLSpanElement>()
const documentTitle = ref('')

const resizeDocTitle = () => {
    if (docTitleHiddenEl.value) {
        docTitleHiddenEl.value.textContent = documentTitle.value
        const documentTitleInput = document.getElementById(
            'document-title-input'
        )
        if (documentTitleInput) {
            documentTitleInput.style.width =
                docTitleHiddenEl.value.offsetWidth + 'px'
        }
    }
}

// watch(
//   () => [documentTitle.value, layoutDocument.title.value],
//   ([newVal, newVal2], [oldVal, oldVal2]) => {
//     resizeDocTitle();
//     if (oldVal2.length == 0 || (newVal === oldVal && oldVal === "...")) {
//       documentTitle.value = newVal2;
//     }
//   }
// );

watch(() => documentTitle.value, resizeDocTitle)

watch(
    () => documentData.value?.title ?? '',
    (newDocTitle) => {
        resizeDocTitle()
        documentTitle.value = newDocTitle
    }
)

const dtHandleKeyDown = (evt: KeyboardEvent) => {
    const documentTitleInput = document.getElementById('document-title-input')
    const { key } = evt

    if (key === 'Escape') {
        documentTitle.value = documentData.value?.title ?? ''
    }
    if (documentTitleInput && (key === 'Escape' || key === 'Enter')) {
        documentTitleInput.blur()
    }
}

const dtHandleBlur = async () => {
    if (documentTitle.value !== documentData.value?.title) {
        await saveDocumentTitle()
        documentTitle.value = documentData.value?.title ?? ''
    }
    toggleDocBreadcrumb()
}

const dtHandleFocus = () => {}

const saveDocumentTitle = async () => {
    if (documentTitle.value.trim().length > 0) {
        const currentContent =
            layoutEditor.editorInstance.value?.ej2Instances?.documentEditor?.serialize() ??
            ''
        const isSaved = await mutateAsync({
            id: documentData.value?.Id ?? '',
            title: documentTitle.value.trim(),
            content: currentContent,
        })

        if (isSaved) {
            layoutEditor.editorInstance.value.ej2Instances.documentEditor.documentName =
                documentData.value?.title
        }
    } else {
        notificationStore.notifyError({
            summary: 'Error saving document title',
            detail: '',
            life: 3000,
        })
    }
}
/* *** */

/* Import audio */
const importAudio = () => {
    const fileInput = document.createElement('input')
    fileInput.type = 'file'
    fileInput.accept = '.mp3, .mp4, .wav'
    fileInput.addEventListener('change', handleAudioFileSelect, false)
    fileInput.click()
}

const handleAudioFileSelect = (event: Event) => {
    // Handle the file selection event and call handleAudioUpload
    const target = event.target as HTMLInputElement
    const files = target.files
    if (files) {
        const fileList: File[] = Array.from(files)
        handleAudioUpload(fileList)
    }
}
/* *** */

const openShareModal = () => {
    updateLayoutEditor({ shareModalOpen: true })
}

watch(
    () => layoutEditor.editorInstance.value,
    (newVal) => {
        if (newVal?.ej2Instances) {
            setTimeout(async () => {
                documentTitle.value = documentData.value?.title ?? ''
                layoutEditor.editorInstance.value.ej2Instances.documentEditor.documentName =
                    documentData.value?.title
                if (!authStore?.userData?.id) {
                    try {
                        authStore.userData = await authService.getUser()
                    } catch (err) {
                        await authService.refresh()
                    }
                }
            }, 200)
        }
    }
)

const [docBreadcrumbVisible, toggleDocBreadcrumb] = useToggle()
const dbBreadcrumbs = computed(() => {
    return getBreadcrumbs()
})
const getBreadcrumbs = () => {
    return documentData.value?.breadcrumbs
}
watch(
    () => docBreadcrumbVisible.value,
    (newVal) => {
        if (!newVal) {
            document.getElementById('document-title-input')?.focus()
        }
    }
)
watch(
    () => documentData?.value?.title,
    (newVal) => {
        const newBreadcrumbs = documentData?.value?.breadcrumbs ?? []
        if (newBreadcrumbs.length > 0) {
            newBreadcrumbs.at(-1).label = newVal
        }
    }
)

/* MOBILE RESPONSIVE */
const browserWidth = ref(window.innerWidth)
const searchDocMobileVisible = ref(false)

const updateCss = () => {
    if (window.innerWidth >= 1024 && browserWidth.value < 1024) {
        searchDocMobileVisible.value = false
    }
    browserWidth.value = window.innerWidth
}

onMounted(() => {
    window.addEventListener('resize', updateCss)
})

onUnmounted(() => {
    window.removeEventListener('resize', updateCss)
})

const providerData = computed(() => ({
    document_id: ref(documentData.value?.Id ?? ''),
    is_public: toValue(documentData?.value?.isPublicShare ?? false),
    share_ids: toValue(documentData?.value?.shareIds ?? []),
}))

provide(documentKey, providerData)

/* *** */
</script>

<template>
    <div
        class="flex justify-content-between align-items-center"
        style="height: 48px"
    >
        <div
            class="flex-column align-items-center"
            :class="{
                hidden: browserWidth < 1024 && searchDocMobileVisible,
                flex: browserWidth >= 1024 || !searchDocMobileVisible,
            }"
        >
            <NuxtLink
                to="/"
                class="h-2rem w-3rem flex justify-content-center align-items-center"
                v-tooltip="'Dashboard'"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="36px"
                    height="36px"
                    viewBox="0 0 50 50"
                >
                    <path
                        d="M 24.962891 1.0546875 A 1.0001 1.0001 0 0 0 24.384766 1.2636719 L 1.3847656 19.210938 A 1.0005659 1.0005659 0 0 0 2.6152344 20.789062 L 4 19.708984 L 4 46 A 1.0001 1.0001 0 0 0 5 47 L 18.832031 47 A 1.0001 1.0001 0 0 0 19.158203 47 L 30.832031 47 A 1.0001 1.0001 0 0 0 31.158203 47 L 45 47 A 1.0001 1.0001 0 0 0 46 46 L 46 19.708984 L 47.384766 20.789062 A 1.0005657 1.0005657 0 1 0 48.615234 19.210938 L 41 13.269531 L 41 6 L 35 6 L 35 8.5859375 L 25.615234 1.2636719 A 1.0001 1.0001 0 0 0 24.962891 1.0546875 z M 25 3.3222656 L 44 18.148438 L 44 45 L 32 45 L 32 26 L 18 26 L 18 45 L 6 45 L 6 18.148438 L 25 3.3222656 z M 37 8 L 39 8 L 39 11.708984 L 37 10.146484 L 37 8 z M 20 28 L 30 28 L 30 45 L 20 45 L 20 28 z"
                    ></path>
                </svg>
            </NuxtLink>
        </div>
        <div
            class="flex-grow-1 overflow-hidden flex-column pr-3 relative main-options-section"
            :class="{
                hidden: browserWidth < 1024 && searchDocMobileVisible,
                flex: browserWidth >= 1024 || !searchDocMobileVisible,
            }"
            style="height: 48px"
        >
            <transition name="slide">
                <div
                    v-if="docBreadcrumbVisible"
                    class="flex align-items-center flex-grow-1 z-1 px-3 dbBreadcrumbs-container"
                >
                    <Breadcrumb
                        :home="null"
                        :model="dbBreadcrumbs"
                        class="dbBreadcrumbs py-0 pl-0 white-space-nowrap"
                    />
                </div>
            </transition>
            <div
                class="flex flex-grow-1 align-items-center justify-content-between"
            >
                <span
                    ref="docTitleHiddenEl"
                    class="px-4 absolute overflow-hidden"
                    style="height: 0; white-space: pre"
                ></span>
                <InputText
                    id="document-title-input"
                    v-model="documentTitle"
                    type="text"
                    v-tooltip.bottom="'Rename'"
                    style="width: 100%; min-width: 350px; max-width: 350px"
                    class="text-base font-bold text-overflow-ellipsis"
                    @keydown="dtHandleKeyDown"
                    @blur="dtHandleBlur"
                    @focus="dtHandleFocus"
                >
                </InputText>
                <div class="ml-3 flex align-items-center">
                    <i
                        class="pi"
                        :class="{
                            'pi-spin pi-spinner': isMutating == 1,
                            'pi-cloud': isMutating != 1,
                        }"
                    ></i>
                    <small class="ml-2 font-italic">{{
                        isMutating == 1
                            ? 'Saving...'
                            : isMutating == 0
                            ? 'Saved'
                            : ''
                    }}</small>
                </div>
            </div>
            <ul class="menu-list flex flex-grow-1 list-none">
                <li
                    v-for="(option, i) in mainOptions"
                    :key="option.label"
                    :index="i"
                    :class="{ hidden: !option.visible }"
                    class="px-1"
                >
                    <Button
                        @click="option.click"
                        :label="option.label"
                        :icon="option?.icon || ''"
                        severity="secondary"
                        size="small"
                        text
                        class="px-2"
                        aria-haspopup="true"
                        aria-controls="overlay_menu"
                        style="height: 22px; color: var(--text-color)"
                    />
                </li>
            </ul>
        </div>

        <div
            v-if="browserWidth >= 1024 || searchDocMobileVisible"
            class="flex search-documents-section"
        >
            <Button
                v-if="browserWidth < 1024 && searchDocMobileVisible"
                icon="pi pi-times"
                severity="danger"
                class="font-medium mr-1"
                size="small"
                rounded
                @click="searchDocMobileVisible = false"
            />
            <SearchDocumentsControl
                :isMutliDiscuss="mutliDiscussDialogVisible"
                style="margin-left: 10px"
            />
        </div>
        <div
            v-if="can('access', 'multi_doc_review')"
            class="flex justify-content-start gap-2 multi-doc"
        >
            <Checkbox
                id="multiDiscuss"
                v-model="mutliDiscussDialogVisible"
                :binary="true"
            />
            <Image
                class="flex justify-content-center align-items-center"
                alt="Multi Documents Icon"
                height="20"
                :src="'/images/MultiDoc.svg'"
            />
            <label
                for="multiDiscuss"
                class="text-900 font-medium mr-8 cursor-pointer"
                v-tooltip.left="
                    'Select multiple documents to work on simultaneously'
                "
            >
                Multi-Doc
            </label>
        </div>

        <ul
            v-if="browserWidth >= 450 || !searchDocMobileVisible"
            class="secondary-options-section"
        >
            <li class="mx-1 editor-sidebar-toggle">
                <Button
                    icon="pi pi-bars"
                    severity="secondary"
                    class="font-medium"
                    rounded
                    outlined
                    @click="toggleEditorSidebar"
                />
            </li>
        </ul>
        <Menu ref="fileMenu" :model="fileMenuOptions" :popup="true" />

        <HelpDailog
            :showModal="helpDialogVisible"
            @update:display="toggleHelpDialog"
        />
        <Toast
            :pt="{
                content: {
                    class: 'flex-column',
                },
                root: {
                    style: {
                        bottom: '70px',
                    },
                },
            }"
            position="bottom-right"
            group="upload"
        >
            <template #message="slotProps">
                <div class="font-medium">
                    {{ slotProps.message.summary }}
                </div>
                <ProgressBar
                    :pt="{
                        label: {
                            class: 'line-height-2',
                        },
                    }"
                    class="w-full h-1rem mt-1"
                    :value="fileUploadProgress"
                ></ProgressBar>
            </template>
        </Toast>
        <DocumentContentUploader
            :visible="contentUploader"
            @update:visible="toggleContentUploader(false)"
        />
        <ShareDocumentDialog
            v-if="documentData?.Id && shareDialogVisible"
            :visible="shareDialogVisible"
            @update:visible="toggleShareDialog"
            :docIds="[documentData?.Id ?? '']"
            :title="documentData?.title ?? ''"
        />
    </div>
</template>

<style lang="scss">
#document-title-input {
    background-color: transparent;
    border: none;
    height: 24px;
    font-size: 18px;
    min-width: 100px;

    &:hover {
        border: 1px solid #0080ff;
    }

    &:focus {
        border: 1px solid #0080ff;
    }
}

.multi-doc {
    position: relative;
    right: 7%;

    @media screen and (max-width: 1439px) and (min-width: 1025px) {
        right: 7%;
        margin-left: 80px;
    }

    @media screen and (max-width: 1024px) and (min-width: 769px) {
        left: 1%;
    }

    @media screen and (max-width: 768px) {
        left: 3%;
        justify-content: flex-end;
        margin-right: 10px;
    }
}

.editor-sidebar-toggle {
    display: none;

    @media (max-width: 1350px) {
        display: block;
    }
}

@media (max-width: 449px) {
    .menu-list {
        //display: none !important;
    }
}

.modal-content {
    padding: 0rem 0rem !important;
}

.main-options-section {
    max-width: calc(65% - 42px - 25rem);
}

.dbBreadcrumbs-container {
    max-width: 100%;
    overflow: hidden;
    height: 25px;
    background-color: var(--surface-ground);
    position: relative;
    white-space: nowrap;

    .p-breadcrumb {
        padding: 0;
        background: transparent;

        .p-menuitem-text {
            color: var(--text-color);
        }

        .p-menuitem-link:focus {
            box-shadow: none;
        }

        li:last-child .p-menuitem-text {
            font-weight: bold;
        }
    }

    .p-inputtext-lg {
        height: 2.5rem;
        font-size: 1rem;
    }

    .p-button-sm {
        width: 2rem;
        height: 2rem;
    }
}

.dbBreadcrumbs-container {
    max-width: 100%;
    overflow: hidden;
    height: 25px;
    background-color: var(--surface-ground);
    position: relative;
    white-space: nowrap;

    .p-breadcrumb {
        padding: 0;
        background: transparent;

        .p-menuitem-text {
            color: var(--text-color);
        }

        .p-menuitem-link:focus {
            box-shadow: none;
        }

        li:last-child .p-menuitem-text {
            font-weight: bold;
        }
    }

    .p-inputtext-lg {
        height: 2.5rem;
        font-size: 1rem;
    }

    .p-button-sm {
        width: 2rem;
        height: 2rem;
    }
}

.search-documents-section {
    display: flex;
    justify-content: flex-start;
    flex-grow: 1;
    max-width: none;

    > .search-documents-control {
        flex-grow: 1;
        max-width: 500px;
    }
}

.secondary-options-section {
    justify-content: flex-end;
    max-width: calc(24rem - 42px);
}

@media (max-width: 1350px) {
    .main-options-section {
        max-width: calc(33% - 42px);
    }

    .search-documents-section {
        justify-content: center;
        max-width: 34%;

        > .search-documents-control {
            flex-grow: 1;
            max-width: 500px;
        }
    }

    .secondary-options-section {
        max-width: 33%;
    }
}

@media screen and (max-width: 1023px) {
    .main-options-section {
        max-width: none;
    }

    .search-documents-section {
        max-width: none;

        > .search-documents-control {
            max-width: none;
        }
    }

    .secondary-options-section {
        max-width: none;
        flex-grow: 0;
    }
}

.dbBreadcrumbs-container {
    text-overflow: ellipsis;
    max-width: 77%;
    overflow: hidden;
    height: 25px;
    background-color: var(--surface-ground);
    position: absolute;
    white-space: nowrap;
}
.dbBreadcrumbs {
    background-color: transparent;
    border: none;
    // overflow-y: hidden;
    overflow: hidden;
}
.slide-enter-active {
    animation: slide-in 0.1s forwards;
}
.slide-leave-active {
    animation: slide-out 0.1s forwards;
}
@keyframes slide-in {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}
@keyframes slide-out {
    0% {
        width: 100%;
    }
    100% {
        width: 0;
    }
}
.custom-input {
    border: 1px solid transparent;
    transition: border-color 0.2s;
    margin-top: 4px; // Add small space from top

    &:hover,
    &:focus {
        border-color: #0080ff;
    }
}
</style>
