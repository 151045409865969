<script setup lang="ts">
import { useAbility } from '@casl/vue'
import { AppAbility } from '@/utils/ability'
import { NotificationStore } from '@/store/notification.store'

const { $notificationStore } = useNuxtApp()
const notificationStore = $notificationStore as NotificationStore

useHead({
    script: [
        {
            src: 'https://asset-tidycal.b-cdn.net/js/embed.js',
            async: false,
        },
    ],
})

const [helpDialogVisible, toggleHelpDialog] = useToggle(false)
const [weeklyTrainingDialogVisible, toggleWeeklyTrainingDialog] =
    useToggle(false)

const { can } = useAbility<AppAbility>()

const canAccessBookLiveSession = computed(() => {
    return can('access', '1_on_1_training')
})

const bookLiveSession = () => {
    if (!canAccessBookLiveSession.value) {
        notificationStore.notifySubscriptionWarning()
        return
    }
    toggleWeeklyTrainingDialog(true)
}

const options = [
    {
        label: 'Dashboard',
        icon: 'pi pi-home text-5xl text-color',
        to: '/',
    },
    // {
    //     label: "Chat",
    //     icon: "pi pi-fw pi-comments",
    //     to: "/chat",
    // },
    // {
    //     label: "Files",
    //     icon: "pi pi-fw pi-folder",
    //     to: "/files",
    // },
    // {
    //     label: "Mail",
    //     icon: "pi pi-fw pi-envelope",
    //     to: "/mail",
    // },
    // {
    //     label: "Task List",
    //     icon: "pi pi-fw pi-check-square",
    //     to: "/task-list",
    // },
]
</script>
<template>
    <div class="flex">
        <ul class="flex list-none align-items-center px-3" style="height: 48px">
            <li
                v-for="(option, i) in options"
                :key="option.label"
                :index="i"
                class="mr-2"
            >
                <NuxtLink :to="option.to">
                    <Button
                        v-tooltip="option.label"
                        :icon="option.icon"
                        severity="secondary"
                        text
                        rounded
                    />
                </NuxtLink>
            </li>
        </ul>
        <div class="flex justify-content-center align-items-center gap-4 mt-1">
            <img
                class="cursor-pointer"
                style="margin-bottom: 1px"
                alt="Icon"
                height="30"
                v-tooltip="'Traning Videos'"
                :src="'/images/weeklyIcon.png'"
                @click="toggleHelpDialog(true)"
            />
            <img
                class="cursor-pointer"
                style="margin-bottom: 4px"
                alt="Icon"
                height="34"
                v-tooltip="'Book live Sessions'"
                :src="'/images/calendar-booking.png'"
                @click="bookLiveSession"
            />
        </div>
    </div>
    <HelpDailog
        :showModal="helpDialogVisible"
        @update:display="toggleHelpDialog"
    />
    <WeeklyTrainingDialog
        :showModal="weeklyTrainingDialogVisible"
        @update:display="toggleWeeklyTrainingDialog"
    />
</template>
<style lang="scss"></style>
