<script setup lang="ts">
import { FileInfo, UploadingEventArgs } from '@syncfusion/ej2-vue-inputs'
import Dropzone, { CustomFileInfo } from './Dropzone.vue'
import { NotificationStore } from '@/store/notification.store'
import { AuthStore } from '@/store/auth.store'
import { transformToSfdtJson } from '@/utils/helpers/editor'
import { useRoute } from 'vue-router'
import AudioFile from '@/assets/icons/audio.svg'
import { useDocument } from '@/composables/document'

const props = defineProps<{
    visible: boolean
}>()

interface FileInfoWithSuccess extends FileInfo {
    success: boolean
}

const route = useRouter()
const documentId = ref(route?.currentRoute?.value?.params?.id ?? '')
const { data: documentData } = useDocument(documentId)
const emit = defineEmits<{
    'update:visible': [value: boolean]
}>()

const config = useRuntimeConfig()
const uploads = ref<FileInfoWithSuccess[]>([])
const { layoutEditor } = useLayout()

const { $notificationStore, $authStore } = useNuxtApp()
const notificationStore = $notificationStore as NotificationStore
const authStore = $authStore as AuthStore

const textUploaderRef = ref<InstanceType<typeof Dropzone> | null>(null)
const pdfUploaderRef = ref<InstanceType<typeof Dropzone> | null>(null)
const audioUploaderRef = ref<InstanceType<typeof Dropzone> | null>(null)

const uploading = ref(false)

const textUploaderProps: ComputedRef<
    InstanceType<typeof Dropzone>['uploaderProps']
> = computed(() => ({
    allowedExtensions: '.doc, .docx, .txt, .pdf, .png, .jpg, .jpeg',
    asyncSettings: {
        saveUrl: `${config.public.syncfusionServerUrl}Import`,
    },
    maxFileSize: 1024000000, // 1024 MB,
    uploading: (e: UploadingEventArgs) => {
        uploads.value = [
            ...uploads.value,
            { ...e.fileData, status: 'uploading', success: false },
        ]
    },
    failure: ({ e, file }: { e: ProgressEvent; file: CustomFileInfo }) => {
        handleFailure(file)
    },
}))

const pdfUploaderProps: ComputedRef<
    InstanceType<typeof Dropzone>['uploaderProps']
> = computed(() => ({
    allowedExtensions: '.pdf',
    asyncSettings: {
        saveUrl: `${config.public.apiUrl}/documents/pdf-to-word`,
    },
    name: 'file',
    maxFileSize: 1024000000, // 1024 MB,
    uploading: (e: UploadingEventArgs) => {
        uploads.value = [
            ...uploads.value,
            { ...e.fileData, status: 'uploading', success: false },
        ]
        e?.currentRequest?.setRequestHeader(
            'Authorization',
            `Bearer ${authStore.accessToken}`
        )
        e.customFormData = [{ document_name: e.fileData.name }]
    },
}))

const audioUploaderProps: ComputedRef<
    InstanceType<typeof Dropzone>['uploaderProps']
> = computed(() => ({
    allowedExtensions: '.mp3, .mp4',
    asyncSettings: {
        saveUrl: `${config.public.apiUrl}/voice2text/transcribe`,
    },
    name: 'file',
    maxFileSize: 1024000000, // 1024 MB,
    uploading: (e: UploadingEventArgs) => {
        uploads.value = [
            ...uploads.value,
            { ...e.fileData, status: 'uploading', success: false },
        ]
        e?.currentRequest?.setRequestHeader(
            'Authorization',
            `Bearer ${authStore.accessToken}`
        )
        const docId = documentData.value?.Id
        if (docId) {
            e.customFormData = [{ document: docId as string }]
        }
    },
}))

const handleFailure = (file: CustomFileInfo) => {
    const tmpUploads = [...uploads.value]
    const uploadFileIndex = tmpUploads.findIndex((item) => item.id === file.id)
    tmpUploads[uploadFileIndex] = {
        ...file,
        status: 'uploaded',
        success: false,
    }
    uploads.value = tmpUploads
}

const fileUploaded = async (
    file: FileInfo,
    e: ProgressEvent,
    cb: () => void,
    type: 'pdf' | 'text' | 'audio'
) => {
    let text = ''
    if (e.currentTarget && 'responseText' in e.currentTarget) {
        if (type === 'pdf') {
            text = e?.currentTarget?.response
        } else if (type === 'text') {
            text = JSON.parse(e?.currentTarget?.responseText as string) ?? ''
        } else if (type === 'audio') {
            const docContent =
                JSON.parse(e?.currentTarget?.responseText as string)
                    .transcript ?? ''
            const sfdtJson = await transformToSfdtJson(
                `<html><body>${docContent}</body></html>`
            )
            text = sfdtJson
        }
    }
    insertTextIntoDocument(text)
    cb()

    const tmpUploads = [...uploads.value]
    const uploadFileIndex = tmpUploads.findIndex((item) => item.id === file.id)
    tmpUploads[uploadFileIndex] = { ...file, status: 'uploaded', success: true }
    uploads.value = tmpUploads
}

watch(uploads, (val) => {
    if (val.length > 0 && val.every((file) => file.status === 'uploaded')) {
        uploading.value = false
        if (val.every((file) => file.success)) {
            uploads.value = []
            notifyUploadSuccess()
            emit('update:visible', false)
        }
    }
})

const insertTextIntoDocument = (text: { sfdt: string }) => {
    layoutEditor.editorInstance.value?.ej2Instances?.documentEditor?.open(text)
}

const notifyUploadSuccess = () => {
    notificationStore.notifySuccess({
        summary: 'Text inserted successfully',
    })
}

const upload = () => {
    uploading.value = true
    textUploaderRef.value?.uploaderRef?.upload()
    pdfUploaderRef.value?.uploaderRef?.upload()
    audioUploaderRef.value?.uploaderRef?.upload()
}

const cancelUpload = async () => {
    uploading.value = false
    textUploaderRef.value?.uploaderRef?.cancel()
    pdfUploaderRef.value?.uploaderRef?.cancel()
    audioUploaderRef.value?.uploaderRef?.cancel()
    uploads.value = []
}
</script>

<template>
    <Dialog
        :visible="visible"
        @update:visible="$emit('update:visible', false)"
        :style="{
            width: '80vw',
            height: '70vh',
            margin: 'auto',
        }"
        position="top"
        modal
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
        :draggable="false"
        header="Import Documents"
        content-class="h-full flex flex-column"
        class="shadow-none"
    >
        <template #default>
            <p class="note font-semibold">
                NOTE: You can't upload a Microsoft Word Document that is already
                open on your desktop. Microsoft will not allow it.
            </p>
            <p>
                Select the type of file you would like to upload or drag and
                drop
            </p>
            <div
                class="grid justify-content-between flex-grow-1 overflow-y-scroll"
            >
                <div class="md:col-4">
                    <div class="flex flex-column h-full">
                        <div class="flex gap-3 align-items-center">
                            <div
                                class="bg-primary w-3rem h-3rem file-icon flex justify-content-center align-items-center"
                            >
                                <i class="pi pi-file text-white text-2xl"></i>
                            </div>
                            <div>
                                <h6 class="mb-1">
                                    Text Document, PDF and Image Files
                                </h6>
                                <p class="mb-0 text-sm text-gray-600">
                                    MS Word, Text File, PDF, Image Files
                                </p>
                            </div>
                        </div>
                        <div class="mt-4 flex-grow-1">
                            <Dropzone
                                ref="textUploaderRef"
                                @file-uploaded="
                                    (file, e, cb) =>
                                        fileUploaded(file, e, cb, 'text')
                                "
                                :uploader-props="textUploaderProps"
                                :show-upload-button="false"
                            />
                        </div>
                    </div>
                </div>
                <div class="md:col-4">
                    <div class="flex flex-column h-full">
                        <div class="flex gap-3 align-items-center">
                            <div
                                class="bg-pink-400 w-3rem h-3rem file-icon flex justify-content-center align-items-center"
                            >
                                <i
                                    class="pi pi-file-pdf text-white text-2xl"
                                ></i>
                            </div>
                            <div>
                                <h6 class="mb-1">
                                    Scanned and Image Based PDF Files
                                </h6>
                                <p class="mb-0 text-sm text-gray-600">PDF</p>
                            </div>
                        </div>
                        <div class="mt-4 flex-grow-1">
                            <Dropzone
                                ref="pdfUploaderRef"
                                @file-uploaded="
                                    (file, e, cb) =>
                                        fileUploaded(file, e, cb, 'pdf')
                                "
                                :uploader-props="pdfUploaderProps"
                                :show-upload-button="false"
                            />
                        </div>
                    </div>
                </div>
                <div class="md:col-4">
                    <div class="flex flex-column h-full">
                        <div class="flex gap-3 align-items-center">
                            <div
                                class="bg-green-400 w-3rem h-3rem file-icon flex justify-content-center align-items-center"
                            >
                                <img
                                    style="width: 24px; height: 24px"
                                    :src="AudioFile"
                                    alt=""
                                />
                            </div>
                            <div>
                                <h6 class="mb-1">Audio/Video Files</h6>
                                <p class="mb-0 text-sm text-gray-600">
                                    MP3, MP4
                                </p>
                            </div>
                        </div>
                        <div class="mt-4 flex-grow-1">
                            <Dropzone
                                ref="audioUploaderRef"
                                @file-uploaded="
                                    (file, e, cb) =>
                                        fileUploaded(file, e, cb, 'audio')
                                "
                                :uploader-props="audioUploaderProps"
                                :show-upload-button="false"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex justify-content-end mt-2 gap-3">
                <Button
                    v-if="uploading"
                    size="small"
                    label="Stop"
                    severity="danger"
                    @click.stop="cancelUpload"
                />
                <Button
                    :disabled="uploading"
                    icon="pi pi-upload"
                    :loading="uploading"
                    loading-icon="pi pi-spinner"
                    @click="upload"
                    :label="uploading ? 'Uploading...' : 'Upload'"
                    size="small"
                />
            </div>
        </template>
    </Dialog>
</template>

<style>
@import '@syncfusion/ej2-base/styles/fabric.css';
@import '@syncfusion/ej2-buttons/styles/fabric.css';
@import '@syncfusion/ej2-vue-inputs/styles/fabric.css';
</style>
<style scoped lang="scss">
.note {
    color: red;
}
.file-icon {
    border-radius: 100%;
}
</style>
