import DocumentService from '@/services/document.service'
import { useMutation, useQueryClient } from '@tanstack/vue-query'

export const useSaveDocumentTitle = () => {
    const { $documentService } = useNuxtApp()
    const documentService = $documentService as DocumentService
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: ['documents', 'saveDocumentTitle'],
        mutationFn: documentService.saveDocumentTitle,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['documents'],
            })
        },
    })
}
